const apiSchy = new Map<string, string>([
  ['50', 'yearOld0'],
  ['51', 'yearOld1'],
  ['52', 'yearOld2'],
  ['53', 'presch1st'],
  ['54', 'presch2nd'],
  ['55', 'presch3rd'],
  ['4', 'elStu1st'],
  ['5', 'elStu2nd'],
  ['6', 'elStu3rd'],
  ['7', 'elStu4th'],
  ['8', 'elStu5th'],
  ['9', 'elStu6th'],
  ['1', 'miStu1st'],
  ['2', 'miStu2nd'],
  ['3', 'miStu3rd'],
  ['10', 'hiStu1st'],
  ['11', 'hiStu2nd'],
  ['12', 'hiStu3rd'],
  ['90', 'general'],
])
export default apiSchy
